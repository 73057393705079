<template>

  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
        <b-breadcrumb-item  active>{{$t('label_referral_contacts')}}</b-breadcrumb-item>
      </div>
    </div>



    <b-alert class="mb-2 mt-2" variant="success" show>
      <!--<div class="alert alert-success" role="alert">-->
        <p>{{$t('label_referral_desc')}}!</p>
      <!--</div>-->


  <template v-if="userRole(authUser()) == 'agent'">
    <!--<?php if ($marketing_url) { ?>-->
    <div v-if="marketing_url" class="m-b-10" role="alert">
      <p class="text-danger"><?= lang('label_copy_link'); ?>:
        <a :href="marketing_url"
           target="_blank">{{marketing_url}}</a></p>

    </div>
    <span v-else class="btn btn-info w-sm waves-effect waves-light m-b-15 ">
										{{referral_code}}
                                    </span>
  </template>

    </b-alert>

     <table-data
             :is-referral="true"

             :filter-data="filterData"
             @addItem="isSidebarOpen = true"
             @editItem="isSidebarOpen = true"
     />


  </div>
</template>

<script>

    import {
        BAlert
    } from 'bootstrap-vue'
    import tableData from './../contact/includes/tablelData'

    export default {
        components: {
            BAlert,
            tableData,
        },
        data() {
            return {

                marketing_url: false,
                referral_code: false,

                filterData: {
                    statusFilter: null,
                    dateFromFilter: null,
                    dateToFilter: null,
                    consultantFilter: null,
                    agentFilter: null,
                    refLinkFilter: 1,
                },
                // curPageLabel: this.$t('label_contacts')

            }
        },

        watch: {
            $route(to, from) {
              this.resolveWhatDataShow(to.path);
            }
        },

        methods: {
            getPageData(){
                this.async('get', '/user/' + this.authUser().id , {params:{}}, function(res){
                    this.marketing_url = res.data.marketing_url;
                        this.referral_code = res.data.referral_code;
                });
            }
        },

        created() {

          this.getPageData();
        },
    }
</script>

